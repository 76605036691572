import Vue from 'vue';
import App from './App.vue';
import TRTC from 'trtc-sdk-v5';
import '@/utils/aegis.js';

import 'core-js/stable';
import 'regenerator-runtime/runtime';


import '@/assets/style/global.css';
import '@/assets/icons';
import '@/assets/style/theme/index.css';
import { isMobile } from '@/utils/utils';
import appConfig from "@/assets/js/config"




if(window._PUBLIC_CONFIG_){
  Object.assign(appConfig, window._PUBLIC_CONFIG_)
}

import {
  Collapse,
  CollapseItem,
  Select,
  Option,
  Input,
  Button,
  Message,
  MessageBox,
  Tooltip,
  Alert,
  Card,
  Empty,
  Dialog
} from 'element-ui';

import router from './router';
import i18n from './locales/i18n';
import axios from "axios";
function getTopLevelDomain() {
  let url =  location.host;
    const parts = url.split('.');
    if (parts.length > 2) {
        // 如果有子域名，则返回最后两个部分作为顶级域名
        return parts[parts.length - 2] + '.' + parts[parts.length - 1];
    }
    // 如果没有子域名，则直接返回原始url
    return url;
}

axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // 例如，添加认证 token
  if( config &&  config.data){
    // config.data['web_id'] = "ddxd.cc"
    // config.data['web_id'] = "0451pc.cn"
    config.data['web_id'] = getTopLevelDomain();
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});
// await getConfig();
let url = appConfig.api + "index/api/geth5";
// axios.post(url, params).then((res) => {});
try{
  let webinfo = await axios.post(url, {});
  if(webinfo && webinfo.status == 200){
    appConfig.sdkAppId = webinfo.data.appid||"";
    appConfig.sdkSecretKey = webinfo.data.key||"";
    appConfig.websocket = webinfo.data.websocket;
    appConfig.callMode = webinfo.data.appidstatus||"1";
    appConfig.webinfo = webinfo.data;
  }
  // appConfig.webinfo['timer'] = '9:00-21:00';
  //  axios.post(url, {}).then((res) => {
  //   const webinfo = res;
  //   if(webinfo && webinfo.status == 200){
  //     appConfig.sdkAppId = webinfo.data.appid||"";
  //     appConfig.sdkSecretKey = webinfo.data.key||"";
  //     appConfig.websocket = webinfo.data.websocket;
  //     appConfig.callMode = webinfo.data.appidstatus||"2";
  //     appConfig.webinfo = webinfo.data;
  //   }
  // });
  

  // appConfig.sdkAppId  = "20013449";
  // appConfig.sdkSecretKey  = "39907fe4f1a943c6f32bf8e62e143bc56a01e67319b5c6493e6c2857484fa805";
  
}catch(e){
  console.log(e);
}

const showMessage = Symbol('showMessage');
class DonMessage {
  success(options, single = true) {
    this[showMessage]('success', options, single);
  }
  warning(options, single = true) {
    this[showMessage]('warning', options, single);
  }
  info(options, single = true) {
    this[showMessage]('info', options, single);
  }
  error(options, single = true) {
    this[showMessage]('error', options, single);
  }
  [showMessage](type, options) {
    Message[type](options);
  }
}

Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(Button);
Vue.use(Tooltip);
Vue.use(Alert);
Vue.use(Card);
Vue.use(Empty)
Vue.use(Dialog)
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$message = new DonMessage();
Vue.prototype.$isMobile = isMobile;

Vue.config.productionTip = false;

document.title = i18n.t('title');
TRTC.setLogLevel(2);
new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
