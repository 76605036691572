import { render, staticRenderFns } from "./comp-info-input.vue?vue&type=template&id=4775e5d0&scoped=true"
import script from "./comp-info-input.vue?vue&type=script&lang=js"
export * from "./comp-info-input.vue?vue&type=script&lang=js"
import style0 from "./comp-info-input.vue?vue&type=style&index=0&id=4775e5d0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!C:\\Users\\Administrator\\AppData\\Roaming\\nvm\\v14.20.0\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4775e5d0",
  null
  
)

export default component.exports