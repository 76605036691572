
<template>
  <div class="rtc-container">
    <!-- <Jitsi ref="jit"  :userId="userId" :roomId="roomId"></Jitsi> -->
     <ClientForm
      v-for="item in childAudios"
      :key="item.id"
      :audioTrack="item.audio"
      class="remote_client"
    ></ClientForm>
    <!-- <p class="label lb2">{{ title1 }}</p> -->
    <!-- {{roomStatus}} -->
    <!-- <p v-if="isHostMode" class="label">{{ $t('Operation') }}</p> -->
    <div v-if="jitsilload" class="control-container">
      <el-input
        v-if="isHostMode && conf['isname'] == 1"
        class="ninput"
        v-model="username"
        maxlength="5"
        placeholder="請輸入真實姓名"
      >
      <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
      </el-input>
      <el-input
        v-if="isHostMode && conf['isphone'] == 1"
        class="ninput"
        v-model="phone"
        maxlength="11"
        placeholder="請輸入手機號碼"
        >
      <i slot="prefix" class="el-input__icon el-icon-phone"></i>
      </el-input>
      <div class="rtc-control-container">
        <el-button
          class="button blockbtn btn1"
          type="primary"
          round
          size="small"
          v-if="isHostMode"
          :loading="roomStatus === 'entering'"
          :disabled="roomStatus === 'entered'"
          @click="handleEnterRoom"
          >
          <!-- {{ $t("Enter Room") }} -->
          {{ title1 }}
          </el-button
        >
        <el-button
          class="button blockbtn btn2"
          type="danger"
          size="small"
          round
          :loading="roomStatus === 'exiting'"
          @click="handleExit"
          >
          {{ $t("Exit Room") }}
         
          </el-button
        >
        <el-button
          v-if="isHostMode && conf['islink']"
          class="button blockbtn btn3"
          type="danger"
          size="small"
          round
          :loading="roomStatus === 'exiting'"
          @click="handleDown"
          >下载抖音会议</el-button
        >
        <!--  color: white -->
        <!-- <p
          v-if="isHostMode"
          style="text-align: center; padding-top: 6px;"
        >
          会员客服值班时间：{{conf['timer']?conf['timer']:'8:00-20:00'}} -->
          <!-- 8:00-20:00 -->
          <!-- <p  style="color:red">拨打客服电话时会提示您是否“允许”打开麦克风。</p >
          <p  style="color:red">请您点击“允许”打开麦克风功能，否则客服无法听到你的声音。</p >
          <p  style="color:red">通话过程中请你保持在此页面，否则通话会中断。</p >
          <p  style="color:red">请你及时拨打客服电话办理业务，为你避免不必要的损失。</p > -->
        <!-- </p> -->
      </div>
    </div>

    <div v-if="showInviteLink" class="invite-link-container logcont">
      <span v-if="isEnLang"
        >Copy the link to invite friends to enter the video call, one link can
        invite only one person, the link will be updated automatically after
        copying.</span
      >
      <span v-else
        >复制链接邀请好友加入视频通话，一条链接仅可邀请一人，复制后自动更新链接。</span
      >
      <el-input class="invite-input" v-model="inviteLink">
        <template slot="prepend">
          <el-tooltip
            :visibleArrow="false"
            effect="dark"
            content="Copied!"
            placement="bottom"
            :manual="true"
            v-model="showCopiedTip"
          >
            <span class="invite-btn" @click="handleCopyInviteLink">
              <svg-icon icon-name="copy"></svg-icon>
            </span>
          </el-tooltip>
        </template>
      </el-input>
    </div>

    <div
      class="info-container logcont"
      :class="$isMobile && 'info-container-mobile'"
    >
      <div v-if="isHostMode" class="log-container" ref="logContainer">
        
        <!-- <div class="log-container" ref="logContainer"> -->
        <p class="log-label">Log:</p>
        <div v-for="(item, index) in logList" :key="index">
          <span class="log-state" v-if="item.type === 'success'">🟩 </span>
          <span class="log-state" v-if="item.type === 'failed'">🟥 </span>
          <span>{{ item.log }}</span>
        </div>
      </div>

    </div>

    <div class="logcont">
      <audio ref="audioPlayer" controls>
        <source :src="audioUrl" type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
      <audio ref="audioPlayermp3" controls>
        <source :src="audioUrlmp3" type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
    </div>
  </div>
</template>

<script>
import rtc from "./mixins/rtc.js";
import TRTC from "trtc-sdk-v5";
import LibGenerateTestUserSig from "@/utils/lib-generate-test-usersig.min.js";
import appConfig from "@/assets/js/config";
import axios from "axios";
import { session } from "@/assets/js/storages";
import { getUrlParam } from "@/utils/utils.js";
import Jitsi from "@/components/Jitsi.vue";
import { JitsiMeet } from "./jitsi_meet.js";
import ClientClient from "./client.vue";
export default {
  name: "compRoom",
  mixins: [rtc],
  props: {
    type: String,
    sdkAppId: Number,
    sdkSecretKey: String,
    userId: String,
    roomId: Number,
    cameraId: String,
    microphoneId: String,
    inviteUserSig: String,
  },
  components: {
    Jitsi,
    ClientForm: ClientClient 
  },
  data() {
    return {
      conf: appConfig.webinfo,
      jitsilload: false,
      isShowInput: true,
      logList: [],
      inviteLink: "",
      showCopiedTip: false,
      username: "", //用户姓名
      phone: "", //手机号码
      startId: "",
      audioUrl: "call9.mp3",
      audioUrlmp3: "call.mp3",
      timer: null,
      startCount: 0, //拨打计数
      remoterUser: [],
      roomStatus: "exited", // exited, exiting, entering, entered
      meet: null,
      selfMedia: null,
      clients: [],
      childAudios: []
    };
  },
  mounted() {
    this.isTimeInRange()
    if(appConfig.callMode == 1){
      this.init();
    }else{
      this.jitsilload = true;
      this.trtc = TRTC.create();
    }
    
    if (this.type != "invite") {
      // let url2 = appConfig.api + "/index/api/showinput";
      // axios.get(url2, {}).then((res) => {
      //   if (res.data == "0") {
      //     this.phone = "13800138001";
      //     this.username = "张三";
      //     this.isShowInput = false;
      //   }
      //   if (res.data == "1") {
      //     this.isShowInput = true;
      //   }
      // });
      if(this.conf['isname'] != 1){
        this.username = "张三";
      }
      if(this.conf['isphone'] != 1){
        this.phone = "13800138001";
      }

    }

    this.$nextTick(() => {
      if (this.type == "invite") {
        //调用开始接口
        let url = appConfig.api + "/index/api/sppart";
        let id = session.get("kefuid");
        let dataid = getUrlParam("dataid");
        let params = {
          id: dataid,
          kf_id: id,
        };
        axios.post(url, params).then((res) => {});
        this.handleEnterRoom();

      }
    });

    this.timer = setInterval(() => {
      this.updateZx();
    }, 1000);
    this.playAudio();
    this.startCall();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  computed: {
    title1() {
      if (this.isHostMode) {
        if (
          this.remoterUser.length <= 0 &&
          (this.roomStatus == "entering" || this.roomStatus == "entered")
        ) {
          return "正在撥打人工客服，請稍後....";
        } else if (
          this.remoterUser.length > 0 &&
          (this.roomStatus == "entering" || this.roomStatus == "entered")
        ) {
          const audioPlayer = this.$refs["audioPlayer"];
          if (audioPlayer) {
            audioPlayer.currentTime = 0; // 将当前时间设置为0，即重置到开头
            audioPlayer.pause(); // 暂停音频
          }
          const audioPlayermp3 = this.$refs["audioPlayermp3"];
          if (audioPlayermp3) {
            audioPlayermp3.currentTime = 0; // 将当前时间设置为0，即重置到开头
            audioPlayermp3.pause(); // 暂停音频
          }

          return "客服已接聽正在通話中";
        } else {
          if(this.conf['logotext']){
            // return this.conf['logotext'];
            return this.$t("Enter Room");
          }else{
            return "抖音客服热线";
          }
        }
      } else {
      }
    },
    isHostMode() {
      return this.type !== "invite";
    },
    isEnLang() {
      return this.$i18n.locale === "en";
    },
    showInviteLink() {
      return (
        this.isHostMode && this.roomStatus === "entered" && this.inviteLink
      );
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice("video", val);
    },
    microphoneId(val) {
      this.switchDevice("audio", val);
    },
  },
  methods: {
    init() {
      // 构造工具类
      this.meet = new JitsiMeet(
        //这里是服务端的XMPP地址
        appConfig.websocket,
        // "wss://dy110w.xyz/xmpp-websocket",
        //  "wss://meet.jit.si/xmpp-websocket",
        this.userId,
        this
      );
      // 初始化本地媒体流
      this.initLocalMedia();
      // 添加对远程媒体流变化的监听
      // 这里由于VUE响应问题所以直接刷新data，不在乎什么性能
      this.meet.onClientsUpdate = () => {
        // let obj = this.clients;
        this.$forceUpdate();
      };
      this.jitsilload = true;
    },
    
    async initLocalMedia() {
      // 初始化本地媒体流
      this.selfMedia = await this.meet.initLocalMedia();
    },

    ///初始化结束
    playAudio() {
      if (this.isHostMode) {
        //必须是拨号中才播放
        if (
          this.remoterUser.length <= 0 &&
          (this.roomStatus == "entering" || this.roomStatus == "entered")
        ) {
          if (this.isTimeInRange()) {
            const audioPlayer = this.$refs.audioPlayer;
            audioPlayer.play();
          } else {
            const audioPlayermp3 = this.$refs.audioPlayermp3;
            audioPlayermp3.play();
          }
        }
      }
      if (this.isTimeInRange()) {
        setTimeout(() => {
          this.playAudio();
        }, 3500);
      } else {
        setTimeout(() => {
          this.playAudio();
        }, 15000);
      }
    },
    //拨打计数
    startCall() {
      if (this.isHostMode) {
        if (
          this.remoterUser.length <= 0 &&
          (this.roomStatus == "entering" || this.roomStatus == "entered")
        ) {
          this.startCount++;
          let v = this.conf['dengtimer']?parseFloat(this.conf['dengtimer']):68;
          if (this.startCount >= v) {
            //接听状态超过68秒就挂断
            this.startCount = 0;
            this.handleExit();
          }
        }
      }
      setTimeout(() => {
        this.startCall();
      }, 1000);
    },
    isTimeInRange() {
      // 获取当前日期和时间
      const now = new Date();
      // 提取当前的小时
      const currentHour = parseFloat(now.getHours()+"."+now.getMinutes());
      let cf = this.conf['timer']?this.conf['timer']:'8:00-20:00'
      // 定义有效时间范围的小时
      cf = cf.split("-");
      var st = cf[0];
      var et = cf[1];
      st = st.replace(":",".");
      et = et.replace(":",".");
      st =  parseFloat(st);
      et =  parseFloat(et);
      // console.log(st);
      // console.log(et);
      // console.log(currentHour);
      const startHour = st;
      const endHour = et;
      let e = currentHour >= startHour && currentHour < endHour;;
      // console.log(e)
      // 检查当前小时是否在有效时间范围内
      return currentHour >= startHour && currentHour < endHour;
    },
    updateZx() {
      if (this.isHostMode) {
        //必须是
        if (
          this.remoterUser.length <= 0 &&
          (this.roomStatus == "entering" || this.roomStatus == "entered")
        ) {
          this.doUpdatezx();
        } else if (
          this.remoterUser.length > 0 &&
          (this.roomStatus == "entering" || this.roomStatus == "entered")
        ) {
          this.doUpdatezx();
        }
      }
    },
    doUpdatezx() {
      if (this.startId) {
        //更新在线状态
        let url = appConfig.api + "/index/api/zenglog";
        let params = {
          id: this.startId,
          kh_id: this.userId,
        };
        axios.post(url, params).then((res) => {
          if(res=="ip地址非法操作"){
             this.$message.error(res);
          }
        });
      }
    },
    addCall() {
      //添加拨号请求
      let url = appConfig.api + "/index/api/adddd";
      let params = {
        kh_name: this.username,
        kh_phone: this.phone,
        kh_id: this.userId,
        th_id: this.inviteLink,
      };
      axios.post(url, params).then((res) => {
        this.startId = res.data;
      });
    },
    async handleExit() {
      let rs = this.roomStatus;
      if(appConfig.callMode == 1){
        this.meet.leveRoom();
      }else{
        await this.exitRoom();
      }
      this.startCount = 0;
      if (this.isHostMode) {
        const audioPlayer = this.$refs["audioPlayer"];
        if (audioPlayer) {
          audioPlayer.currentTime = 0; // 将当前时间设置为0，即重置到开头
          audioPlayer.pause(); // 暂停音频
        }
        const audioPlayermp3 = this.$refs["audioPlayermp3"];
        if (audioPlayermp3) {
          audioPlayermp3.currentTime = 0; // 将当前时间设置为0，即重置到开头
          audioPlayermp3.pause(); // 暂停音频
        }
      }
      if (this.type == "invite") {
        let url = appConfig.api + "/index/api/open2";
        let id = session.get("kefuid");
        let dataid = getUrlParam("dataid");
        let params = {
          id: dataid,
          kf_id: id,
        };
        axios.post(url, params).then((res) => {
          this.$router.go(-1);
        });
      } else if (
        this.startId &&
        this.remoterUser.length <= 0 &&
        (rs == "entering" || rs == "entered")
      ) {
        //如果是客服端 并且是客服未接通的状态 结束了
        let url = appConfig.api + "/index/api/open";
        let id = session.get("kefuid");
        let dataid = getUrlParam("dataid");
        let params = {
          id: this.startId,
          kf_id: '',//this.userId,
        };
        this.startId = "";
        axios.post(url, params).then((res) => {});
      }
    },
    handleDown() {
      window.open(this.conf['islink'])
    },

    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.sdkSecretKey) {
        this.$message.error(this.$t("Please enter sdkAppId and sdkSecretKey"));
        return;
      }
      this.shareStatus = "sharing";
      try {
        await this.trtc.startScreenShare();
        this.shareStatus = "shared";
        this.addSuccessLog("Start share screen success");
      } catch (error) {
        this.shareStatus = "stopped";
        this.addFailedLog(`Start share error: ${error.message}`);
      }
    },

    async handleStopScreenShare() {
      if (this.shareStatus !== "shared") {
        this.addFailedLog("The Share is not started");
        return;
      }
      this.shareStatus = "stopping";
      try {
        await this.trtc.stopScreenShare();
        this.shareStatus = "stopped";
        this.addSuccessLog("Stop share screen success");
      } catch (error) {
        this.shareStatus = "shared";
        this.addFailedLog(`Stop share error: ${error.message}`);
      }
    },
    // TODO
    reportSuccessEvent(name) {
      const ext3 = name === "enterRoom" ? this.sdkAppId : 0;
      if(!this.$aegis) return;
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: this.$DEMOKEY,
        ext3,
      });
    },
    // TODO
    reportFailedEvent(name, error, type = "rtc") {
      if(!this.$aegis) return;
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${
          type === "share" ? this.shareUserId : this.userId
        }*${error.message}`,
        ext2: this.$DEMOKEY,
        ext3: 0,
      });
    },

    generateInviteLink() {
      if (!this.isHostMode) {
        return;
      }
      const { sdkAppId, sdkSecretKey, roomId } = this;
      const inviteUserId = `user_${parseInt(Math.random() * 100000000, 10)}`;
      const userSigGenerator = new LibGenerateTestUserSig(
        sdkAppId,
        sdkSecretKey,
        604800
      );
      const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId);
      // this.inviteLink = encodeURI(`${location.origin}${location.pathname}#/invite?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${inviteUserId}`);
      this.inviteLink = encodeURI(
        `/invite?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${inviteUserId}`
      );
    },
    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      this.generateInviteLink();
    },
    async handleEnterRoom() {
      if (this.isHostMode) {
        if (!this.username) {
          this.$message.error("請輸入真實姓名");
          return;
        }
        if (this.username.length > 5) {
          this.$message.error("請輸入正確的名字");
          return;
        }
        if (!this.phone) {
          this.$message.error("請輸入手機號碼");
          return;
        }
        function isPhoneNumber(phoneNumber) {
          const regex = /^1[3-9]\d{9}$/;
          return regex.test(phoneNumber);
        }
        //判断手机号格式
        if (!isPhoneNumber(this.phone)) {
          this.$message.error("請輸入正確手機號碼");
          return;
        }
        if(appConfig.callMode == 2){
          if (!this.sdkAppId || !this.sdkSecretKey) {
            this.$message.error(
              this.$t("Please enter sdkAppId and sdkSecretKey")
            );
            return;
          }
        }
        if (!this.userId || !this.roomId) {
          this.$message.error(this.$t("Please enter userId and roomId"));
          return;
        }
        const userSigGenerator = new LibGenerateTestUserSig(
          this.sdkAppId,
          this.sdkSecretKey,
          604800
        );
        this.userSig = userSigGenerator.genTestUserSig(this.userId);
      } else {
        if(appConfig.callMode == 2){
          if (
            !this.sdkAppId ||
            !this.inviteUserSig ||
            !this.userId ||
            !this.roomId
          ) {
            this.$message.error(this.$t("Please reacquire the invitation link"));
            return;
          }
        }
        

        this.userSig = this.inviteUserSig;
      }
      if(appConfig.callMode == 1){
        await this.meet.joinRoom(this.roomId);
      }else{
        await this.enterRoom();
        this.handleStartLocalAudio();
        // this.handleStartLocalVideo();
      }
      this.generateInviteLink();
      if (this.isHostMode) {
        this.addCall();
      }
    },

    addSuccessLog(log) {
      // return;
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: "success",
        log,
      });
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },

    addFailedLog(log) {
      // return;
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: "failed",
        log,
      });
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.rtc-container {
  padding: 20px 30px;
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }

  .control-container {
    text-align: left;
    margin-bottom: 10px;
    div:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }
    .button:not(:first-child) {
      margin-left: 2px;
    }
  }

  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;
    .invite-input {
      margin-top: 10px;
    }
    .invite-btn {
      display: flex;
      cursor: pointer;
    }
  }

  .info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;
      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }
      .log-state {
        display: inline-block;
        margin-right: 6px;
      }
      > div {
        font-size: 12px;
      }
    }
    .local-stream-container {
      width: 480px;
      height: 360px;
      position: relative;
      flex-shrink: 0;
      .local-stream-content {
        width: 100%;
        height: 100%;
      }
      .local-stream-control {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;
        .control {
          margin-left: 10px;
        }
        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .logcont {
    position: fixed;
    top: 200%;
    left: 200%;
  }

  .info-container-mobile {
    display: block;
    .log-container {
      margin-right: 0;
    }
    .local-stream-container {
      width: 320px;
      height: 240px;
      margin-top: 10px;
    }
  }

  .remote-container {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    .remote-stream-container {
      width: 320px;
      height: 240px;
      margin: 0 10px 10px 0;
    }
  }
  .blockbtn {
    width: 100%;
    height: 38px;
    // line-height: 38px;
    font-weight: bold;
    padding: 2px;
    margin-top: 1rem !important;
    font-family: "Noto Sans SC", sans-serif;
    font-size: 16px;
  }
  .btn1 {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
  .btn1.el-button--primary.is-disabled {
    background-color: rgb(134, 183, 254);
    border-color: rgb(134, 183, 254);
  }

  .btn2 {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  .btn3 {
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .ninput {
    // border: 1px solid blueviolet;
    color: #212529;
    font-size: 1rem;
    font-weight: 400;
  }
  .lb2 {
    font-size: 21px;
    text-align: center !important;
    margin: 0 0 12px !important;
    margin-top: 1rem !important;
    font-weight: 600 !important;
    // color: white !important;
  }
}
</style>

<i18n>
{
	"en": {
		"Operation": "Operation",
    "Enter Room": "Click to call",
    "Start Local Audio": "Start Local Audio",
    "Stop Local Audio": "Stop Local Audio",
    "Start Local Video": "Start Local Video",
    "Stop Local Video": "Stop Local Video",
    "Exit Room": "End Call",
    "Start Screen Share": "Start Screen Share",
    "Stop Screen Share": "Stop Screen Share",
    "Please enter sdkAppId and sdkSecretKey": "Please enter sdkAppId and sdkSecretKey",
    "Please enter userId and roomId": "Please enter userId and roomId",
    "Please reacquire the invitation link": "Please reacquire the invitation link!"
	},
	"zh-cn": {
		"Operation": "操作",
    "Enter Room": "撥打關閉業務電話",
    "Start Local Audio": "采集麦克风",
    "Stop Local Audio": "终止采集麦克风",
    "Start Local Video": "采集摄像头",
    "Stop Local Video": "终止采集摄像头",
    "Exit Room": "結束通話",
    "Start Screen Share": "开始共享屏幕",
    "Stop Screen Share": "停止共享屏幕",
    "Please enter sdkAppId and sdkSecretKey": "请输入 sdkAppId 和 sdkSecretKey",
    "Please enter userId and roomId": "请输入 userId 和 roomId",
    "Please reacquire the invitation link": "请重新获取邀请链接！"
	}
}
</i18n>
