import { render, staticRenderFns } from "./comp-room.vue?vue&type=template&id=7a6e11a4&scoped=true"
import script from "./comp-room.vue?vue&type=script&lang=js"
export * from "./comp-room.vue?vue&type=script&lang=js"
import style0 from "./comp-room.vue?vue&type=style&index=0&id=7a6e11a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!C:\\Users\\Administrator\\AppData\\Roaming\\nvm\\v14.20.0\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a6e11a4",
  null
  
)

/* custom blocks */
import block0 from "./comp-room.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports