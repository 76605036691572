<template>
  <router-view/>
</template>

<script>
// import VConsole from 'vconsole';
export default {
  name: 'App',
  mounted() {
      // // 或者使用 options 选项初始化
      // const vConsole = new VConsole({ maxLogNumber: 1000 });
   try {
    if(!this.$aegis) return;
    this.$aegis.reportEvent({
      name: 'loaded',
      ext1: 'loaded-success',
      ext2: this.$DEMOKEY,
    });
   } catch (error) {
    console.log(error);
   }
  },
};
</script>
