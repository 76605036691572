import { render, staticRenderFns } from "./App.vue?vue&type=template&id=5d25a63f"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!C:\\Users\\Administrator\\AppData\\Roaming\\nvm\\v14.20.0\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports