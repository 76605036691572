import { render, staticRenderFns } from "./Jitsi.vue?vue&type=template&id=7153b297&scoped=true"
import script from "./Jitsi.vue?vue&type=script&lang=js"
export * from "./Jitsi.vue?vue&type=script&lang=js"
import style0 from "./Jitsi.vue?vue&type=style&index=0&id=7153b297&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!C:\\Users\\Administrator\\AppData\\Roaming\\nvm\\v14.20.0\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7153b297",
  null
  
)

export default component.exports