<template>
  <div class="Jitsi-container" v-if="roomId">
    <vue-jitsi-meet
      ref="jitsiRef"
      domain="dy110w.xyz"
      :options="jitsiOptions"
    ></vue-jitsi-meet>
  </div>
</template>

<script>
import { JitsiMeet } from "@mycure/vue-jitsi-meet";
// import { JitsiMeet } from "./jitsi_meet.js";
import appConfig from "@/assets/js/config"


export default {
  name: "Jitsi",
  components: {
    "vue-jitsi-meet": JitsiMeet,
  },
  props: {
    roomId: {
      type: [String, Number],
      default: "",
    },
    userId: {
      type: [String, Number],
      default: "",
    },
  },
  created() {
    // this.init();
  },
  data() {
    return {
      loading: false,
      // jitsiOptions: {
      //   roomName: '2134234234',
      //   noSSL: false,
      //   userInfo: {
      //     email: "",
      //     displayName: this.userId,
      //   },
      //   configOverwrite: {
      //     prejoinPageEnabled: false,
      //     enableNoisyMicDetection: false,
      //     deeplinking:{
      //       disabled: true
      //     }
      //   },
      //   interfaceConfigOverwrite: {
      //     SHOW_JITSI_WATERMARK: false,
      //     SHOW_WATERMARK_FOR_GUESTS: false,
      //     SHOW_CHROME_EXTENSION_BANNER: false,
      //     MOBILE_APP_PROMO: false
      //   },
      //   onload: this.onIFrameLoad,
      // },
    };
  },

  methods: {
    init() {
      // 构造工具类
      this.meet = new JitsiMeet(
        //这里是服务端的XMPP地址
        appConfig.websocket,
        // "wss://dy110w.xyz/xmpp-websocket",
        //  "wss://meet.jit.si/xmpp-websocket",
        this.userId
      );
      // 初始化本地媒体流
      this.initLocalMedia();
      // 添加对远程媒体流变化的监听
      // 这里由于VUE响应问题所以直接刷新data，不在乎什么性能
      this.meet.onClientsUpdate = () => {
        this.$forceUpdate();
      };
      this.beVideoStart = true;
      this.onIFrameLoad();
    },
    Disconnect() {
      // 离开房间（这一步会断开连接）
      this.meet.leveRoom();
      this.beInRoom = false;
    },
    async initLocalMedia() {
      // 初始化本地媒体流
      this.selfMedia = await this.meet.initLocalMedia();
    },
    onIFrameLoad() {
      this.loading = true;
      this.$emit("onload");
      // this.$refs.jitsiRef.addEventListener(
      //   "participantJoined",
      //   this.onParticipantJoined
      // );
      // this.jitsiOptions.userInfo.displayName = this.userId;
      // this.$refs.jitsiRef.executeCommand('setAudioOnly',true);
      // // debugger
      // // this.$refs.jitsiRef.executeCommand('addBreakoutRoom',this.roomId);
      // this.$refs.jitsiRef.jitsiApi.getRoomsInfo().then(devices => {
      // });
      //  this.$refs.jitsiRef.jitsiApi.startRecording();
    },
    onParticipantJoined(e) {},
    joinMeet() {
      return new Promise((resolve, reject) => {
        this.meet.joinRoom("2134234234");
        this.beInRoom = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Jitsi-container {
  height: 500px;
}
</style>
