import { render, staticRenderFns } from "./svg-icon.vue?vue&type=template&id=7665e0b5&scoped=true"
import script from "./svg-icon.vue?vue&type=script&lang=js"
export * from "./svg-icon.vue?vue&type=script&lang=js"
import style0 from "./svg-icon.vue?vue&type=style&index=0&id=7665e0b5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!C:\\Users\\Administrator\\AppData\\Roaming\\nvm\\v14.20.0\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7665e0b5",
  null
  
)

export default component.exports